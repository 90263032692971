<template>
  <div class="emptyResults">
    <font-awesome-icon icon="heart-broken" size="10x" flip="horizontal" />
    No results :(
  </div>
</template>

<style lang="scss" scoped>
.emptyResults {
  margin-top: 20vh;
  @include center-flex-vh;
  @include col;
  font-size: 1.5em;
}
</style>

